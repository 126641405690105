html {
    font-family: Helvetica, Sans-Serif;
    font-size: 0.9rem;
    padding: 2rem !important;
    color: #777777
}

.table td {
    border-bottom: none;
}

a {
    color: #000;
    font-weight: 600;
}

a:hover {
    color: #a3a3a3;
    cursor: pointer;
    text-decoration: none;
}